html, body {
    margin: 0;
}

.bg {
    background-color: #f8f8f8;
}

.MuiBreadcrumbs-li p {
    font-weight: lighter;
}